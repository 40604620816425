import { InputMask } from "@react-input/mask";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import Swal from "sweetalert2";
import useApi from "../hooks/useApi";
import { Suspense, useCallback, useEffect, useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function Component() {
  const formRef = useRef<HTMLFormElement>(null);

  const api = useApi();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { register, handleSubmit, formState, setValue, setError, reset } = useForm({
    defaultValues: {
      nome: "",
      email: "",
      mensagem: "",
      telefone: "",
      recaptcha_token: "",
    },
  });

  const loadRecaptcha = useCallback(async () => {
    if (executeRecaptcha) {
      executeRecaptcha("contact").then((token) => setValue("recaptcha_token", token));
    }
  }, [executeRecaptcha, setValue]);

  const handleSuccess = useCallback(() => {
    reset();

    Swal.fire({
      title: "Mensagem enviada!",
      text: "Fique de olho na sua sua caixa de entrada. Em breve entraremos em contato.",
      icon: "success",
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  }, [reset]);

  const handleError = useCallback(
    (error: any) => {
      Object.entries<any>(error.data ?? []).forEach(([field, messages]) => {
        if (field === "recaptcha_token") {
          loadRecaptcha();
        }

        setError(field as any, { type: "manual", message: messages[0] });
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: error.message,
      });
    },
    [loadRecaptcha, setError]
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      Swal.fire({
        title: "Enviando mensagem...",
        text: "Aguarde um momento, estamos enviando sua mensagem.",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

      api.contact(data).then(handleSuccess).catch(handleError);
    },
    [api, handleError, handleSuccess]
  );

  useEffect(() => {
    register("recaptcha_token", {
      required: "O Google reCAPTCHA não foi carregado corretamente. Atualize a página e tente novamente.",
    });
    
    loadRecaptcha();
  }, [register, loadRecaptcha]);

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">FALE CONOSCO</h1>

          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit(onSubmit)}
                className="mb-5"
                autoComplete="off"
                autoCorrect="off"
                noValidate
              >
                <div className="mb-2">
                  <Row>
                    <Col xs={12} className="mb-3">
                      <Form.Label htmlFor="nome">Nome Completo*</Form.Label>
                      <Form.Control
                        id="nome"
                        type="text"
                        isInvalid={!!formState.errors.nome}
                        {...register("nome", {
                          required: "Este campo é obrigatório",
                        })}
                        autoFocus
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.nome?.message}
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs={12} md={6} className="mb-3">
                      <Form.Label htmlFor="email">E-mail*</Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        isInvalid={!!formState.errors.email}
                        {...register("email", {
                          required: "Este campo é obrigatório",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.email?.message}
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs={12} md={6} className="mb-3">
                      <Form.Label htmlFor="telefone">Telefone*</Form.Label>
                      <Form.Control
                        as={InputMask}
                        mask="(__) _____-____"
                        placeholder="(__) _____-____"
                        replacement={{ _: /\d/ }}
                        isInvalid={!!formState.errors.telefone}
                        {...register("telefone", {
                          required: "Este campo é obrigatório",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.telefone?.message}
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs={12} className="mb-3">
                      <Form.Label htmlFor="mensagem">Mensagem*</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        id="mensagem"
                        isInvalid={!!formState.errors.mensagem}
                        {...register("mensagem", {
                          required: "Este campo é obrigatório",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formState.errors.mensagem?.message}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>

                  {formState.errors.recaptcha_token && (
                    <Alert variant="danger" className="mb-3">{formState.errors.recaptcha_token.message}</Alert>
                  )}
                </div>


                <div className="d-flex flex-column flex-lg-row gap-3 justify-content-between">
                  <div className="order-2 order-lg-1">
                    <p>Outros canais de comunicação e-mail:</p>
                    <div className="mb-3">
                      <i className="bi bi-envelope me-2"></i>
                      <a
                        className="mb-2"
                        href="mailto: contato@carrinhocheiooanointeiro.com.br"
                        rel="noopener noreferrer"
                      >
                        E-MAIL: contato@promocao120horas.com.br
                      </a>
                      <br />
                    </div>
                    <p>
                      Dúvidas, fale com a nossa equipe
                      <br />
                      horário de atendimento de segunda a sexta-feira, das 8:00
                      às 18:00
                      <br />
                      sua mensagem será respondida em até 72 horas úteis
                    </p>
                  </div>

                  <div className="order-1 orderl-lg-2 mb-3">
                    <Button type="submit" variant="primary" className="button">
                      ENVIAR
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Suspense>
  );
}
